.left-bottom-div {
  position: fixed; /* Fixed positioning to keep it at the bottom left */
  bottom: 0; /* Align at the bottom */
  left: 0; /* Align to the left */
  color: #ba9e6c; /* Text color set to white */
  font-weight: bold; /* Make text bold */
  font-size: small; /* Smaller font size */
  padding: 10px; /* Padding to not stick to the very edge */
  z-index: 1000;

  display: flex;
  align-items: center;
}
.status-indicator {
  margin-left: 5px;
}
