@import url("./container.css");

.form-box {
  padding: 10px;
  max-width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.toggle-icon {
  cursor: pointer;
  align-self: flex-end;
}

.form-header {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-button {
  margin-top: 8px;
  font-size: 0.7rem;
  cursor: pointer;
}

.text-field {
  width: 100%;
  margin: 8px 0;
}

.helper-text {
  font-size: 0.8rem;
  color: red;
}

.forget-password {
  font-size: 0.6rem;
  cursor: pointer;
}

.submit-button {
  margin-top: 16px;
  margin-bottom: 16px;
}
