@import url("./container.css");

.user-div {
  position: fixed; /* Fixed positioning to keep it at the bottom left */
  top: 10px; /* Align at the top */
  right: 10px; /* Align to the left */
  padding: 20px; /* Padding inside the div */
  box-shadow: -10px 0 15px -5px #333; /* Optional: adds shadow to the left edge */
  display: flex; /* Use flexbox for layout */
  flex-direction: row; /* Stack children vertically */
  align-items: start; /* Align children to the start (left) */
  box-sizing: border-box; /* Padding and border included in width */
  margin-right: 10px;
  border-radius: 5px;
  z-index: 1000;
  align-items: center;
}
