.bottom-div {
  position: fixed; /* Fixed positioning relative to the viewport */
  z-index: 1000;
  bottom: 0; /* Align the bottom of the div with the bottom of the viewport */
  background-color: #213d64;
  padding: 10px; /* Padding on top and bottom for some space inside */
  margin: 10px;
  text-align: center; /* Center-align the text inside the div */
  border-radius: 5px;
  box-shadow: -10px 0 15px -5px #333; /* Optional: adds shadow to the left edge */

  display: flex; /* Use flexbox to align children */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 30px; /* Set a specific height for your bar */
  left: 50%; /* Move the center of the element to the middle of the screen */
  transform: translateX(-50%); /* Shift it back by half of its own width */
}

.highlighted {
  background-color: #5978a3;
  border-color: #ff0000; /* Par exemple, un contour rouge */
}
