.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border-radius: 8px;

  color: #f2f3ee;
  background-color: #1e2938;
  box-shadow: -10px 0 15px -5px #333;
}
