@import url("./container.css");

.left-top-div {
  display: flex;
  align-items: center;
}

.add-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.workspace-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.workspace-item {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.layer-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.layer-item {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
