.right-div {
  position: fixed; /* Fixed positioning relative to the viewport */
  top: 50%; /* Start at half the height of the viewport */
  right: 0; /* Align to the right side */
  transform: translateY(
    -50%
  ); /* Shift the div up by half its own height to center vertically */
  width: 10%; /* Adjust the width as needed */
  background-color: #213d64;
  padding: 20px; /* Padding inside the div */
  box-shadow: -10px 0 15px -5px #333; /* Optional: adds shadow to the left edge */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  align-items: start; /* Align children to the start (left) */
  box-sizing: border-box; /* Padding and border included in width */
  margin-right: 10px;
  border-radius: 5px;
  z-index: 1000;
}

/* Styling for the header, paragraph, and button for consistency */
.right-div h1,
.right-div p,
.right-div button {
  margin: 10px 0; /* Add top and bottom margin for spacing */
  width: 100%; /* Full width elements */
}

.right-div button {
  cursor: pointer; /* Change cursor to pointer on hover over the button */
  padding: 10px 20px; /* Padding inside the button */
  background-color: #69aa87; /* Example: blue background for the button */
  color: white; /* White text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners for the button */
  font-size: 16px;
}

/* Add hover effect for button */
.right-div button:hover {
  background-color: rgb(56, 141, 96); /* Darken button on hover */
}

.right-div .remove-button:hover {
  background-color: #d32f2f;
}
